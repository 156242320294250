import { ComponentProps } from 'react'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import MuiDialog, { DialogProps } from '@material-ui/core/Dialog'

import Box from '@base/Box'
import CloseButton from '@base/CloseButton'

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  paper: {
    margin: 0,
    maxHeight: '100%',
    [breakpoints.up('md')]: {
      margin: '2rem',
      maxHeight: 'calc(100% - 4rem)',
    },
  },
}))

type Props = DialogProps & {
  iconStyle?: string
  iconProps?: ComponentProps<typeof Box>
  onClose?: () => void
  closeBtn?: boolean
}

const Dialog = ({ className, classes, iconStyle, children, iconProps, onClose, closeBtn = true, ...props }: Props) => {
  const ownClasses = useStyles({})

  return (
    <MuiDialog
      maxWidth='md'
      classes={{
        ...classes,
        paper: cx(ownClasses.paper, className, classes ? classes.paper : undefined),
      }}
      onClose={onClose}
      {...props}
    >
      {children}
      {closeBtn && <CloseButton {...{ iconStyle, iconProps, onClose }} />}
    </MuiDialog>
  )
}

export default Dialog
