import { useTranslation } from '@src/i18n'

import Typography from '@base/Typography'
import Box from '@base/Box'

const NetworkError = () => {
  const { t } = useTranslation()

  return (
    <Box data-testid='networkError' my={2} textAlign='center'>
      <Typography component='div' variant='h6'>
        {'onLine' in navigator && !navigator.onLine ? t('base.error.offline') : t('base.error.message')}
      </Typography>
    </Box>
  )
}

export default NetworkError
