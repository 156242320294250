import { ComponentProps } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import Cancel from '@material-ui/icons/Cancel'
import IconButton, { IconButtonProps as IconButtonPropsType } from '@material-ui/core/IconButton'

import Box from '@base/Box'

type Props = {
  iconButtonProps?: IconButtonPropsType
  iconStyle?: string
  iconProps?: ComponentProps<typeof Box>
  onClose?: () => void
}

const CloseButton = ({ iconButtonProps, iconStyle, iconProps, onClose }: Props) => {
  const theme: Theme = useTheme()

  return (
    <Box
      position='absolute'
      top={{ xs: 0, sm: 10 }}
      right={{ xs: 0, sm: 10 }}
      color={theme.palette.customCol.silver}
      {...iconProps}
    >
      <IconButton aria-label='Close' color='inherit' onClick={onClose} {...iconButtonProps}>
        <Cancel className={iconStyle} />
      </IconButton>
    </Box>
  )
}

export default CloseButton
